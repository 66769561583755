.visual {
    height: 100%;
    object-fit: cover;
    margin-bottom: -0.5rem;
    z-index: -2;
}

.visual-content {
    position: absolute;
    width: 100%;
    top: 7rem;
}

@media screen and (max-width: 767px) {
    .visual-content {
        top: 5rem;
    }
}
